import * as React from "react";
import Badge from "@leafygreen-ui/badge";
import { MdCloudQueue } from "react-icons/md";
import GlobalStorage from "../_Generics/GlobalStorage";
import Feedback from "../Feedback/Feedback";
import { TiZoomIn } from "react-icons/ti";
import Countdown from "../Countdown/Countdown";
import ElapsedTime from "../ElapsedTime/ElapsedTime";
import { Link } from "react-router-dom";
import { FaExclamationTriangle } from 'react-icons/fa';
import {BsQuestionCircleFill} from "react-icons/all";
import { Tooltip } from "@mui/material";
import ToolTip from '@leafygreen-ui/tooltip';
import useDarkMode from "use-dark-mode";
import { PhnIcon } from '../Phn';


/**
 * Format hub data with default string data from key specified in column config
 * @param data hub data item
 */
export function StringRenderer(data: any) {
    let obj = '';
    if (typeof data.cellData !== "string") {
        for (let prop in data.cellData) {
            obj += data.cellData[prop] + ' ';
        }
    } else {
        obj = data.cellData;
    }
    return (
        <div className={data.className}>{obj}</div>
    )
}

/**
 * Render Proactive badge given hub item
 * @param data hub data item
 */
export function renderProactive(data: any) {
    if (data.isProactive) {
        return <Badge
            className="proactive-badge badge-green"
            variant="green"
            title="This case was opened on behalf of the customer">PROACTIVE</Badge>
    }

    return "";
}

export function isPartnerCase(data: any) {
    const partnerCaseTypes = [
        "Cloud",
        "ISV",
        "Strategic Cloud",
        "Strategic ISV",
        "Strategic Technology",
        "Technology",
    ]

    return (partnerCaseTypes.includes(data?.account?.Partner_Type__c) ?? false)
}

export function renderPartnerBadge(data: any)
{
    if (isPartnerCase(data)) {
      return (
        <Badge
          className="partner-badge badge-blue"
          variant="blue"
          title="This case is a Partner case"
        >
          {"PARTNER"}
        </Badge>
      );
    }

    return "";
}

function RenderAutoCloseToolTipInfo(label:string){
     switch (label) {
         case 'AutoCloseSF1' : return 'AutoClose Stage 1' ; 
         case 'AutoCloseSF2' : return 'AutoClose Stage 2' ; 
         case 'AutoCloseSF3' : return 'AutoClose Stage 3' ; 
         case 'AutoCloseSF4' : return 'AutoClose Stage 4' ;
     }
}

/**
 * Render autoclose stage tooltip given a hub item
 * @param data
 */
export function TimeToAutoClose(data:any){
    const item: any = data.rowData;

    if (item.status === 'Waiting for Customer'){        
        return(
            <div>
              {item.CALC_waitingTime + ' '}
                {item.autoCloseStage &&
                <Tooltip title={item.autoCloseStage.STAGE ?
                    'In ' + RenderAutoCloseToolTipInfo(item.autoCloseStage.STAGE) :
                    "Until Auto Close Stage 1 : " + item.autoCloseStage.TIME_TO_INIT + ' days'}
                         placement="right-end"
                         arrow>
                <span className="question-circle">                    
                  <BsQuestionCircleFill />                   
                </span>
                </Tooltip>
                }
            </div>)
    } else {
        return(
            <div>
             {item.CALC_waitingTime + ' '}
            </div>)
        }
}

/**
 * Render AutoClose badge given hub item
 * @param data hub data item
 */
export function renderAutoClose(data: any) {
    if (data.autoClose) {
        return <Badge
            className="autoclose-badge badge-green"
            variant="green"
            title="In AutoClose process">AUTOCLOSE</Badge>
    }

    return "";
}

/**
 * Render AutoClose warningsign badge given hub item
 * @param data hub data item
 */
export function renderAutoCloseWarningSign(data: any) {
    const title = "Majority work type and Root cause need to be set";
    if (data.autoClose || (data.autoCloseStage && data.autoCloseStage.TIME_TO_INIT === -1)) {
        return <div title={title} className="warning-autoclose" >
            <FaExclamationTriangle size="20" />
        </div>
    }

    return "";
}

/**
 * Check if the property reviewers exist so we can conclude this is a review case and if SLA then show the label
 * Render SLA badge given hub item
 * @param data hub data item
 */
export function renderSLA(data: any) {

    if (data.isSLA && data.hasOwnProperty('reviewers')) {
        return <Badge
            className="sla-badge badge-red"
            variant="red"
            title="SLA">SLA</Badge>
    }

    return "";
}

/**
 * Check if item is salesforce.
 * Really only checking that the id starts with 0
 * @param item hub data item
 */
export function isItemSalesforce(item: any) {
    return item
        .id
        .indexOf("0") === 0;
}

/**
 * Render escalation badge based on hub data item
 * @param item hub data item
 */
export function renderEscalated(item: any) {
    if (item.escalated && item.escalated === true) {
        return <Badge className="case-component fts-badge badge-red" variant="red" title="Escalated">ESC</Badge>
    }
    return "";
}

/**
 * Render stickies badge based on hub data item
 * @param item hub data item
 */
export function renderStickies(item: any) {
    if (item.escalated && item.escalated === true && item.stickies && Object.keys(item.stickies).length !== 0) {
        return <Badge className="case-component stickies-badge badge-green" variant="green" title="Sticky">Sticky</Badge>
    }
    return "";
}

/**
 * Render link with correct target and rel attributes
 * @param {string} href 
 * @param {string} title 
 * @param {string} content 
 */
export function renderLink(href: string, title: string, content: string) {
    return <a href={href} title={title} target="_blank" rel="noopener noreferrer">{content}</a>;
}

/**
 * Render all components based on hub data item
 * @param item hub data item
 */
export function renderComponents(item: any) {
    const triageComps = (item.triageComps && item.triageComps.length > 0)
        ? item.triageComps
        : item.triageComponents;
    const comps = (item.comps && item.comps.length > 0)
        ? item.comps
        : item.components;
    if (comps && comps.length > 0) {
        return (comps.map((comp: any, idx: number) => (
            <Badge className="case-component" variant="green" key={idx}>{comp}</Badge>
        )));
    } else if (triageComps && triageComps.length > 0) {
        return (triageComps.map((comp: any, idx: number) => (
            <Badge className="case-component badge-lightgray" variant="lightgray" key={idx}>{comp}</Badge>
        )));
    }
    return "";
}

/**
 * Convert user name from first.last to First Last
 * @param {string} s expects first.last
 */
export function getNameFromUsername(s: string) {
    let splitStr = s.split('.');
    return splitStr[0]
        .charAt(0)
        .toUpperCase() + splitStr[0].substring(1) + ' ' + splitStr[1]
            .charAt(0)
            .toUpperCase() + splitStr[1].substring(1);
}




/**
 * Render link to Project from react-base-table column
 * @param data react-base-table column
 */
export function Account(data: any) {
    const item: any = data.rowData;

    const darkMode = useDarkMode(false, {
        classNameDark: 'dark',
        classNameLight: 'light'
    });

    if (item.source && item.source.includes('mongodbgov'))
        return '( redacted )';

    let project = (item.cloudProject && item.cloudProject.length > 0) ? item.cloudProject : item.sf_project_id;
    const projectUrl = project && project.length ?
        "https://hub.corp.mongodb.com/" + project :
        "https://corp.mongodb.com/clienthub/jira/view?name=" + item.company;

    return (
        <>  
            <ToolTip
                darkMode={darkMode.value ? true :false}
                align="bottom"
                justify="end"
                trigger={
                    <span>
                        <a href={projectUrl} target="_blank" rel="noopener noreferrer">
                            {item.company}
                        </a>
                    </span>
                }
                triggerEvent="hover">
                <div className="tc-tooltip-popover">
                    <b>PROJECT</b><br/>
                    {item.project ? item.project : ''}<br/>
                </div>
            </ToolTip>

            <PhnIcon project={item} />
        </>
    )
}   

/**
 * Render cloud icon from react-base-table column
 * @param data react-base-table column
 */
export function Cloud(data: any) {
    const item: any = data.rowData;
    return (item.cloudProject && item.cloudProject.length > 0)
        ? <span className="cloud-badge"><MdCloudQueue /></span>
        : '';
}

/**
 * Render follow up badge from react-base-table column
 * @param data react-base-table column
 */
export function FollowUp(data: any) {
    const item: any = data.rowData;
    return <Badge
        className={"sla-badge badge-" + item.CALC_slaColor}
        variant={item.CALC_slaColor}>
        {item.CALC_slaTimeStr}
    </Badge>
}

/**
 * Render link to salesforce or jira from react-base-table column
 * @param data react-base-table column
 */
export function Id(data: any) {
    const item: any = data.rowData;
    if (isItemSalesforce(item)) {
        if (item.source && item.source.includes('mongodbgov'))
            return renderLink(`https://${item.source}.my.salesforce.com/` + item.sfdcCaseId, "Open Salesforce case #" + item.id, item.id);
        else
            return renderLink("https://hub.corp.mongodb.com/case/" + item.id, "Open Salesforce case #" + item.id, item.id);
    }
    if (item.jiraCaseId) {
        return renderLink("https://jira.mongodb.org/browse/" + item.jiraCaseId, "Open Jira ticket #" + item.id, item.id);
    }
    return <span>MISSING-DATA</span>;
}

/**
 * Return FTS or SLA from react-base-table column
 * @param data react-base-table column
 */
export function Label(data: any) {
    const item: any = data.rowData;

    let label = '';
    if (item.isFTS === 1) {
        label = "FTS";
    } else if (item.isSLA === 1) {
        label = "SLA";
    }
    return label;
}

/**
 * Return the last comment created by name from react-base-table column.
 * @param data react-base-table column
 */
export function LastComment(data: any) {
    const item: any = data.rowData;

    if (item.source && item.source.includes('mongodbgov')) {
        return '( redacted )';
    } else {
        return item.lastPublicCommentUser && item.lastPublicCommentUser.CreatedByName;
    }
}

/**
 * Return the lookers from a react-base-table column
 * @param data react-base-table column
 */
export function Lookers(data: any) {
    const item: any = data.rowData;
    //TODO: not sure how the data is returned yet
    return item.lookers && item
        .lookers
        .map((r: string) => getNameFromUsername(r))
        .join(', ');
}

/**
 * Return the reviewers in a comma separated list from a react-base-table column
 * @param data react-base-table column
 */
export function Reviewers(data: any) {
    const item: any = data.rowData;
    //TODO: not sure how the data is returned yet
    return item.reviewers && item
        .reviewers
        .map((r: string) => getNameFromUsername(r))
        .join(', ');
}

/**
 * Return the reviewers in a comma separated list from a react-base-table column
 * @param data react-base-table column
 */
export function Severity(data: any) {
    const item: any = data.rowData;
    return (isItemSalesforce(item)
        ? "S"
        : "P") + item.priority;
}
/**
 * 
 * Render FTS badge given hub item
 * @param data hub data item
 */
export function renderFTS(data: any) {

    if (data.isFTS) {
        return <Badge
            className="badge-green"
            variant="green"
            title="FTS">FTS</Badge>
    }

    return "";
}

/**
 * Return the SLA badge from a react-base-table column
 * @param data react-base-table column
 */
export function SLA(data: any) {
    const item: any = data.rowData;
    return <Badge
        className={"sla-badge badge-" + item.CALC_slaColor}
        variant={item.CALC_slaColor}>
        {item.CALC_slaTimeStr}
    </Badge>
}

/**
 * Return the FTS Next Action badge from a react-base-table column
 * @param data react-base-table column
 */
export function FTSNextAction(data: any) {
    const item: any = data.rowData;
    return item.ftsNextAction && item.ftsNextAction !== '' ? <Badge
        className={"sla-badge badge-" + item.CALC_nextActionColor}
        variant={item.CALC_nextActionColor.split('-')[0]}>
        {item.ftsNextAction}
    </Badge> : ''
}

export function formatDate(dateValue : Date) {
    const dateString : string = dateValue.toISOString().replace(/[TZ]/g, ' ');
    const eventDate : string = dateString.split(' ')[0];
    const eventHour : string = dateString.split(' ')[1].split(':')[0];
    const eventMinutes : string = dateString.split(' ')[1].split(':')[1];
    return eventDate + ' ' + eventHour + ':' + eventMinutes;
}

export function FTSEventStart(data: any) {
    const item: any = data.rowData;
    return item.FTSEventStart && item.FTSEventStart !== null ? formatDate(item.FTSEventStart) : '-';
}

export function FTSEventEnd(data: any) {
    const item: any = data.rowData;
    return item.FTSEventEnd && item.FTSEventEnd !== null ? formatDate(item.FTSEventEnd) : '-';
}

/*
 * Return the SLA badge from a react-base-table column
 * @param data react-base-table column
 */
export function CountDownSeconds(data: any) {
    const item: any = data.rowData;

    // if(!item.timestamp) {
    //   return null;
    // } else if (item.CALC_remainingSeconds < 0) {
    //     return <Badge className={"sla-badge badge-red"} variant={"red"}> 
    //     MISSED
    //     </Badge>
    // 

    // if (item.CALC_remainingSeconds < 0) {
    //     return <Badge className={"sla-badge badge-red"} variant={"red"}> <div>oh no!</div>
    //     MISSED
    //     </Badge>
    //   } else { 
    return <Countdown
        /* timestamp={item.timestamp} */
        isSLA={item.isSLA}
        isFollowUp={item.CALC_slaTime.isFollowUp}
        // days={item.CALC_slaTime.days}
        // hours={item.CALC_slaTime.hours}
        // minutes={item.CALC_slaTime.minutes}
        // seconds={item.CALC_slaTime.seconds} 
        //expiresAt={item.slaExpiresAt ? item.slaExpiresAt : item.followUpExpireAt}
        expiresAt={item.slaExpiresAt}
        color={item.CALC_slaColor} />
    // } 

    /* display={item.CALC_slaTime.display*/
}

export function FollowUpCountDown(data: any) {
    const item: any = data.rowData;

    // if (item.CALC_remainingSeconds < 0) {
    //     return <Badge className={"sla-badge badge-red"} variant={"red"}> 
    //     MISSED
    //     </Badge>
    //   } else { 
    return <Countdown
        isSLA={item.isSLA}
        isFollowUp={item.CALC_slaTime.isFollowUp}
        expiresAt={item.followUpExpireAt ?? item.slaExpiresAt}
        color={item.CALC_slaColor} />
    // } 
}

export function WaitingElapsedTime(data: any) {
    const item: any = data.rowData;
    // if(!item.timestamp) {
    //     return null;
    // } else {
    return <ElapsedTime
        timestamp={item.timestamp}
        days={item.CALC_waitingTimeObj.days}
        hours={item.CALC_waitingTimeObj.hours}
        minutes={item.CALC_waitingTimeObj.minutes}
        seconds={item.CALC_waitingTimeObj.seconds} />
    //}  
}

/**
 * Render the waiting score column
 * @param data react-base-table column
 */
export function WaitingScore(data: any) {
    const item: any = data.rowData;
    let waitingMinutes = item.CALC_waitingSeconds / 60;
    if (item.CALC_initialResponseSLAHours !== 0) {
        return Math.round(waitingMinutes / item.CALC_initialResponseSLAHours);
    } else {
        return 0;
    }

}

/**
 * Render the TagsAndTitle column from a react-base-table column
 * @param data react-base-table column
 */
export function TagsTitle(data: any) {
    const myCasesView = data.container.props.rowKey === 'USERWAITING';
    const needsReviewSection = data.container.props.rowKey === 'REVACT';

    const item: any = data.rowData;
    return <span>
        {item.desc && item.desc}
        {myCasesView && renderAutoCloseWarningSign(item)}
        {item.description && item.description}
        {renderEscalated(item)}
        {renderProactive(item)}
        {renderSLA(item)}
        {renderPartnerBadge(item)}
        {needsReviewSection && renderFTS(item)}
        {renderComponents(item)}
        {myCasesView && renderAutoClose(item)}
        {/* TSTOOLS-2324 {renderStickies(item)} */}
    </span>
}
/**
 * Render the AssigneeOrRecommendedAssignee from a react-base-table column
 * @param data react-base-table column
 */
export function Assignee(data: any) {
    const item: any = data.rowData;
    const autoTCData = GlobalStorage.autoTCData.autoTC;
    if (item && item.assignee && item.assignee !== '') {
        return <span className="assignee">{item.assignee}</span>;
    } else if (item.recommendedAssignee) {
        return <span className="recommendedAssignee">
            <Link to="/" onClick={() => {
                window.open(`https://autotc-inspector.corp.mongodb.com/history/${autoTCData.caseAnalysisId}`, '_blank');
                return null;
            }}>
                {item.recommendedAssignee}</Link>
        </span>
    } else {
        return <span className="assignee"></span>
    }
}

/**
 * Render the feedback col from a react-base-table column
 * @param data react-base-table column
 */
export function RenderFeedback(data: any) {
    const item: any = data.rowData;

    if (item.recommendedAssignee != null) {
        return <Feedback stitchClient={GlobalStorage.stitchClient} autoTCData={GlobalStorage.autoTCData} item={item}></Feedback>
    }
    return '';
}
/**
 * Render the detail action react-base-table column
 * @param data react-base-table column
 */
export function DetailAction(data: any) {
    const item: any = data.rowData;

    if (item.source && item.source.includes('mongodbgov'))
        return <></>

    return <div className="case-action" onClick={(ev: any) => GlobalStorage.openDetailsCallback(item.id)} data-test-id={`${item.id}-case-action-btn`}>
        <span className="hvr-pulse-grow"><TiZoomIn /></span>
    </div>
}
/**
 * Render the TCWatchout score column
 * @param data react-base-table column
 */
export function TCWScore(data: any) {
    const item: any = data.rowData;
    return item.tcwscore;
}
/**
 * Render the TCWatchout issye typlogy SLA/FTS/UNA column
 * @param data react-base-table column
 */
export function TCWTypology(data: any) {
    const item: any = data.rowData;
    return item.tcwtypology;
}
/**
 * Render the TCWatchout TagsAndTitle column from a react-base-table column
 * @param data react-base-table column
 */
export function TCWTagsTitle(data: any) {
    const item: any = data.rowData;
    return <span>
        {renderEscalated(item)}
        {renderSLA(item)}
        {renderPartnerBadge(item)}
        {renderComponents(item)}
        {renderProactive(item)}
        {/* TSTOOLS-2324 {renderStickies(item)} */}
        {" " + item.desc || " " + item.description}
    </span>
}
/**
 * Loose list for renders, this maps functions to the data in get columns
 */
export const Renderers = {
    string: StringRenderer,
    Project: Account,
    cloud: Cloud,
    followup: FollowUp,
    id: Id,
    label: Label,
    lastcomment: LastComment,
    lookers: Lookers,
    reviewers: Reviewers,
    severity: Severity,
    sla: SLA,
    sla_countdown: CountDownSeconds,
    followup_countdown: FollowUpCountDown,
    waiting_elapsedTime: WaitingElapsedTime,
    waiting_score: WaitingScore,
    waiting_autoclose: TimeToAutoClose,
    tagstitle: TagsTitle,
    assignee: Assignee,
    feedback: RenderFeedback,
    detailAction: DetailAction,
    ftsNextAction: FTSNextAction,
    eventStart: FTSEventStart,
    eventEnd: FTSEventEnd,
    tcwscore: TCWScore,
    tcwtypology: TCWTypology,
    tcwtagstitle: TCWTagsTitle
}

export function ColumnConfig() {
    /** This function returns the column definitions to be used by using index of a column type. 
     * Ie.
     * ["id", "Project"]
    */
    function getColumns(): { [key: string]: any } {
        return {
            id: {
                title: "Id",
                key: "id",
                dataKey: "id",
                sortAccessor: (row: any) => isItemSalesforce(row) ? row.sfscCaseId : row.jiraCaseId,
                format: "id",
                format_tc: "id",
                format_user:"id",
                width: 85,
                sortable: true,
                minWidth: 85,
            },
            Project: {
                title: "Account",
                key: "company",
                dataKey: "company",
                format: "Project",
                format_tc: "Project",
                format_user: "Project",
                width: 150,
                sortable: true,
                className: "account-column"
            },
            sev: {
                title: "Sev",
                key: "priority",
                dataKey: "priority",
                format: "severity",
                format_tc: "severity",
                format_user: "severity",
                width: 60,
                sortable: true
            },
            sla: {
                title: "SLA",
                key: "CALC_remainingSeconds",
                dataKey: "CALC_remainingSeconds",
                format: "sla",
                format_tc: "sla_countdown",
                format_user: "sla_countdown",
                width: 120,
                sortable: true
            },
            tags_title: {
                title: "Title and Tags",
                key: "desc",
                dataKey: "desc",
                format: "tagstitle",
                format_tc: "tagstitle",
                format_user: "tagstitle",
                width: 300,
                sortable: false,
                headerClassName: "column-expandable",
                className: "column-expandable"
            },
            feedback: {
                title: "Feedback",
                width: 100,
                key: "feedback",
                format: "feedback",
                format_tc: "feedback"
            },
            assignee: {
                title: "Assignee",
                key: "assignee",
                width: 150,
                format: "assignee",
                format_tc: "assignee",
                sortAccessor: (row: any) => row.assignee ? row.assignee : row.recommendedAssignee,
                // accessor: this.renderAssignee,         
                // sortAccessor: this.sortAssignee 
            },
            recommend: {
                title: "Recommend",
                key: "assignee",
                width: 150,
                format: "assignee",
                format_tc: "assignee",
                // accessor:this.renderAssignee 
            },
            follow_up: {
                title: "Follow Up",
                key: "CALC_remainingSeconds",
                dataKey: "CALC_remainingSeconds",
                format: "sla",
                format_tc: "followup_countdown",
                width: 120,
                sortable: true
            },
            waiting: {
                title: "Waiting",
                key: "CALC_waitingTime",
                dataKey:  "CALC_waitingTime",
                format_user: "waiting_autoclose",
                format_tc: "waiting_elapsedTime",
                width: 100,
                sortAccessor: (row: any) => row.CALC_waitingSeconds,
                sortable: true
            },
            // waiting_elapsedTime: {
            //     title: "Waiting",
            //     key: "CALC_waitingTime",
            //     dataKey: "CALC_waitingTime",
            //     // format: "waiting_elapsedTime",
            //     format_tc: "waiting_elapsedTime",
            //     width: 100,
            //     /* sortAccessor: (row: any) => row.CALC_waitingSeconds,*/
            //     sortable: true
            // },
            waitingScore: {
                title: "Score",
                key: "CALC_waitingSeconds",
                dataKey: "CALC_waitingSeconds",
                format: "waiting_score",
                format_tc: "waiting_score",
                width: 100,
                // sortAccessor: (row: any) => row.CALC_waitingSeconds, //waitingSeconds - e.g. 2940
                sortable: true
            },
            last_comment: {
                title: "Last Cmt",
                key: "lastPublicCommentUser",
                dataKey: "lastPublicCommentUser",
                format: "lastcomment",
                format_tc: "lastcomment",
                format_user: "lastcomment",
                width: 150,
                sortable: false
            },
            fts_next_action: {
                title: "Next Action",
                key: "ftsNextAction",
                dataKey: "ftsNextAction",
                width: 150,
                sortable: true,
                format: "ftsNextAction",
                format_tc: "ftsNextAction"
            },
            requested_by: {
                title: "Requested By",
                key: "requestedby",
                dataKey: "requestedby",
                width: 150,
                sortable: false
            },
            reviewers: {
                title: "Reviewers",
                key: "reviewers",
                dataKey: "reviewers",
                format: "reviewers",
                format_tc: "reviewers",
                width: 150,
                sortable: false
            },
            looking: {
                title: "Looking",
                key: "lookers",
                dataKey: "lookers",
                format: "lookers",
                format_tc: "lookers",
                width: 150,
                sortable: false
            },
            status: {
                title: "Status",
                key: "status",
                dataKey: "status",
                width: 150,
                sortable: false
            },
            label: {
                title: "Label",
                key: "isSLA",
                dataKey: "isSLA",
                sortable: false,
                width: 150,
                format: "label",
                format_tc: "label",
            },
            cloud: {
                title: "Type",
                key: "cloudProject",
                dataKey: "cloudProject",
                format: "cloud",
                format_tc: "cloud",
                format_user: "cloud",
                width: 60,
                sortable: false,
            },
            details: {
                title: "",
                key: "detailId",
                dataKey: "detailId",
                format: "detailAction",
                format_tc: "detailAction",
                width: 60,
                sortable: false,
            },
            tcwscore: {
                title: "Score",
                width: 100,
                key: "tcwscore",
                format: "tcwscore",
                format_tc: "tcwscore",
                sortable: true
            },
            tcwtypology: {
                title: "Typology",
                width: 120,
                key: "tcwtypology",
                format: "tcwtypology",
                format_tc: "tcwtypology"
            },
            tcwslaorfollowup: {
                title: "SLA or Follow Up",
                key: "CALC_remainingSeconds",
                dataKey: "CALC_remainingSeconds",
                format: "sla",
                format_tc: "sla",
                width: 140,
                sortable: true
            },
            tcw_tags_title: {
                title: "Tags And Title",
                key: "desc",
                dataKey: "desc",
                format: "tcwtagstitle",
                format_tc: "tcwtagstitle",
                width: 300,
                sortable: false,
                headerClassName: "column-expandable",
                className: "column-expandable"
            },
            eventStart: {
                title: "Start Time (UTC)",
                key: "eventStart",
                format: "eventStart",
                sortable: false,
                width: 200,
            },
            eventEnd: {
                title: "End Time (UTC)",
                key: "eventEnd",
                format: "eventEnd",
                sortable: false,
                width: 200
            }
        };
    }

    const columns = getColumns();

    /**
     * Build the sort object from our easy configson the columns.
     * @param sort 
     */
    function getSort(sort: any[]) {
        let sortOrder: any = {
            orderOfSorts: [],
            sortState: {}
        }
        sort.forEach((s) => {
            for (let prop in s) {


                sortOrder.orderOfSorts.push(columns[prop].key);
                sortOrder.sortState[columns[prop].key] = s[prop];
            }

        })
        return sortOrder;
    }
    /** Here we are creating the table definitions with all the columns.
     * Creating them with a string array allowed us to seperate out each 
     * column definition for easier management.
     */
    return {
        "SLA": {
            title: "Active SLAs",
            columns: [
                "cloud",
                "id",
                "Project",
                "sla",
                "sev",
                "assignee",
                "feedback",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "sla": "asc" }])
        },
        "UNA": {
            title: "Active Unassigned",
            columns: [
                "cloud",
                "id",
                "Project",
                "follow_up",
                "waiting",
                // "waiting_elapsedTime",
                "sev",
                "recommend",
                "feedback",
                "last_comment",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "follow_up": "asc" }])
        },
        "REVACT": {
            title: "Needs Review",
            columns: [
                "cloud",
                "id",
                "waiting",
                //  "waiting_elapsedTime",
                "waitingScore",
                "sev",
                "requested_by",
                "reviewers",
                "looking",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "waitingScore": "desc" }, { "sev": "asc" }])
        },
        "FTSA": {
            title: "Pending Event(s)",
            columns: [
                "cloud",
                "id",
                "Project",
                "waiting",
                "fts_next_action",
                "sev",
                "eventStart",
                "eventEnd",
                "assignee",
                "last_comment",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "fts_next_action": "desc" }])
        },
        "FSA": {
            title: "Follow the Sun (Active)",
            columns: [
                "cloud",
                "id",
                "Project",
                "follow_up",
                "waiting",
                "fts_next_action",
                // "waiting_elapsedTime",
                "sev",
                "assignee",
                "last_comment",
                "feedback",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "fts_next_action": "asc" }])
        },
        "FSW": {
            title: "Follow the Sun (Waiting)",
            columns: [
                "cloud",
                "id",
                "Project",
                "waiting",
                "fts_next_action",
                "sev",
                "assignee",
                "last_comment",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "waiting": "desc" }])
        },
        "USERASSIGNED": {
            title: 'Assigned and Open Support Cases (work on these every working day)',
            columns: [
                "cloud",
                "id",
                "Project",
                "sla",
                "waiting",
                "sev",
                "last_comment",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "sla": "asc" }])
        },
        "USERASSIGNEDOTHER": {
            title: "Other Cases Waiting on Me (check up on these every working day or two)",
            columns: [
                "cloud",
                "id",
                "Project",
                "waiting",
                "sev",
                "last_comment",
                "status",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "waiting": "desc" }])
        },
        "USERWAITING": {
            title: "Assigned and Waiting (check up on these every working day or two)",
            columns: [
                "cloud",
                "id",
                "Project",
                "waiting",
                "sev",
                "last_comment",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "waiting": "desc" }])
        },
        "USERRESOLVED": {
            title: "Assigned and Resolved (classify and close these as soon as they are resolved)",
            columns: [
                "cloud",
                "id",
                "Project",
                "waiting",
                "sev",
                "last_comment",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "waiting": "desc" }])
        },
        "USERONHOLD": {
            title: "Assigned and Inactive (check up on these at least every 60 days)",
            columns: [
                "cloud",
                "id",
                "Project",
                "waiting",
                "sev",
                "last_comment",
                "status",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "waiting": "desc" }])
        },
        "REVDONE": {
            title: "Reviewed",
            columns: [
                "cloud",
                "id",
                "waiting",
                "sev",
                "feedback",
                "requested_by",
                "reviewers",
                "looking",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "sev": "asc" }, { "waiting": "desc" }])
        },
        "UNAS": {
            title: "Active Unassigned",
            columns: [
                "cloud",
                "id",
                "Project",
                "sla",
                "waiting",
                "sev",
                "label",
                "last_comment",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "sla": "asc" }])
        },
        "UNWAITING": {
            title: "Waiting and Unassigned",
            columns: [
                "cloud",
                "id",
                "Project",
                "sla",
                "waiting",
                "sev",
                "label",
                "last_comment",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "waiting": "desc" }])
        },
        "ACTS": {
            title: "All Active Tickets",
            columns: [
                "cloud",
                "id",
                "Project",
                "sla",
                "waiting",
                "sev",
                "assignee",
                "last_comment",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "sla": "asc" }])
        },
        "WAIT": {
            title: "Waiting for Customer",
            columns: [
                "cloud",
                "id",
                "Project",
                "waiting",
                "sev",
                "assignee",
                "last_comment",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "waiting": "desc" }])
        },

        "AUTOCLOSE": {
            title: 'Waiting in Autoclose',
            columns: [
                "cloud",
                "id",
                "Project",
                "waiting",
                "sev",
                "assignee",
                "last_comment",
                "tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "waiting": "desc" }])
        },
        "TCW": {
            title: "Next Up",
            columns: [
                "tcwscore",
                "cloud",
                "id",
                "Project",
                "recommend",
                "feedback",
                "tcwslaorfollowup",
                "sev",
                "tcwtypology",
                "tcw_tags_title",
                "details"
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "tcwscore": "desc" }])
        }
    }
}
